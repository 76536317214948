import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Bem from 'bemHelper';
import { I18nHoc, Trans } from 'helpers/i18n';
import Row, { Col } from 'components/row';
import Icon, { iconTypes } from 'components/icon';

import { renderDomikLink } from './block-count';
import imgRisk from '../assets/imgOurRating.jpg';

import translates from '../home-i18n.json';

const classes = new Bem('blockEvaluation');

@I18nHoc(translates)
export default class EvaluationBlock extends Component {
  static propTypes = {
    className: PropTypes.string
  };

  render() {
    const { className } = this.props;

    return (
      <div {...classes({ extra: className })}>
        <Trans lang="ru">
          <Row {...classes('row', 'block')}>
            <Col {...classes('col', 'info')} allPr={0}>
              <div {...classes('text', 'h2')}><h2 children="Наша оценка эффективна" /></div>
              <Row {...classes('row', 'list')} mtColumn mpColumn>
                <Col {...classes('col')} allPr={0}>
                  <div>
                    <Icon {...classes('icon')} type={iconTypes.iconBuildsBC} />
                  </div>
                  <div {...classes('topic')}>
                    <p {...classes('text')}>Создатель проекта {renderDomikLink.call(null, classes)}</p>
                    <span {...classes('separator')} />
                  </div>
                  <div {...classes('description')}>
                    <p {...classes('text', 'description')}>{renderDomikLink.call(null, classes)} - это самый старый информационный ресурс на рынке недвижимости Украины.</p>
                  </div>
                </Col>
                <Col {...classes('col')} allPr={0}>
                  <div>
                    <Icon {...classes('icon')} type={iconTypes.iconHandshakeBC} />
                  </div>
                  <div {...classes('topic')}>
                    <p {...classes('text')} children="Команда экспертов" />
                    <span {...classes('separator')} />
                  </div>
                  <div {...classes('description')}>
                    <p {...classes('text', 'description')} children="Состоит из экспертов рынка, риэлторов и юристов с многолетним опытом работы. Мы понимаем главные риски." />
                  </div>
                </Col>
                <Col {...classes('col')} allPr={0}>
                  <div>
                    <Icon {...classes('icon')} type={iconTypes.iconShieldBC} />
                  </div>
                  <div {...classes('topic')}>
                    <p {...classes('text')} children="Индекс надёжности" />
                    <span {...classes('separator')} />
                  </div>
                  <div {...classes('description')}>
                    <p {...classes('text', 'description')} children="Мы считаем Индекс надёжности на основании авторской методологии, которая учитывает все возможные параметры оценки." />
                  </div>
                </Col>
                <Col {...classes('col')} allPr={0}>
                  <div>
                    <Icon {...classes('icon')} type={iconTypes.iconDocumentsBC} />
                  </div>
                  <div {...classes('topic')}>
                    <p {...classes('text')} children="Репутация застройщика" />
                    <span {...classes('separator')} />
                  </div>
                  <div {...classes('description')}>
                    <p {...classes('text', 'description')} children="Помимо законности и документальных факторов мы учитываем историю и репутацию застройщика." />
                  </div>
                </Col>
              </Row>
            </Col>
            <Col {...classes('col', 'img')} allPr={0} mp={0}>
              <div {...classes('imgContainer')}>
                <img {...classes('img')} src={imgRisk} alt="дома будущего" loading="lazy" decoding="async" />
              </div>
            </Col>
          </Row>
        </Trans>
        <Trans lang="ua">
          <Row {...classes('row', 'block')}>
            <Col {...classes('col', 'info')} allPr={0}>
              <div {...classes('text', 'h2')}><h2 children="Наша оцінка ефективна" /></div>
              <Row {...classes('row', 'list')} mtColumn mpColumn>
                <Col {...classes('col')} allPr={0}>
                  <div>
                    <Icon {...classes('icon')} type={iconTypes.iconBuildsBC} />
                  </div>
                  <div {...classes('topic')}>
                    <p {...classes('text')}>Творець проєкту {renderDomikLink.call(null, classes)}</p>
                    <span {...classes('separator')} />
                  </div>
                  <div {...classes('description')}>
                    <p {...classes('text', 'description')}>{renderDomikLink.call(null, classes)} - це найстаріший інформаційний ресурс на ринку нерухомості України.</p>
                  </div>
                </Col>
                <Col {...classes('col')} allPr={0}>
                  <div>
                    <Icon {...classes('icon')} type={iconTypes.iconHandshakeBC} />
                  </div>
                  <div {...classes('topic')}>
                    <p {...classes('text')} children="Команда експертів" />
                    <span {...classes('separator')} />
                  </div>
                  <div {...classes('description')}>
                    <p {...classes('text', 'description')} children="Складається з експертів ринку, ріелторів та юристів з багаторічним досвідом роботи. Ми розуміємо головні ризики." />
                  </div>
                </Col>
                <Col {...classes('col')} allPr={0}>
                  <div>
                    <Icon {...classes('icon')} type={iconTypes.iconShieldBC} />
                  </div>
                  <div {...classes('topic')}>
                    <p {...classes('text')} children="Індекс надійності" />
                    <span {...classes('separator')} />
                  </div>
                  <div {...classes('description')}>
                    <p {...classes('text', 'description')} children="Ми рахуємо Індекс надійності на підставі авторської методології, яка враховує всі можливі параметри оцінки." />
                  </div>
                </Col>
                <Col {...classes('col')} allPr={0}>
                  <div>
                    <Icon {...classes('icon')} type={iconTypes.iconDocumentsBC} />
                  </div>
                  <div {...classes('topic')}>
                    <p {...classes('text')} children="Репутація забудовника" />
                    <span {...classes('separator')} />
                  </div>
                  <div {...classes('description')}>
                    <p {...classes('text', 'description')} children="Крім законності і документальних факторів ми враховуємо історію і репутацію забудовника." />
                  </div>
                </Col>
              </Row>
            </Col>
            <Col {...classes('col', 'img')} allPr={0} mp={0}>
              <div {...classes('imgContainer')}>
                <img {...classes('img')} src={imgRisk} alt="будинки майбутнього" loading="lazy" decoding="async" />
              </div>
            </Col>
          </Row>
        </Trans>
      </div>
    );
  }
}
