import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Bem from 'bemHelper';
import { I18nHoc, Trans } from 'helpers/i18n';
import Link from 'components/link';
import Row, { Col } from 'components/row';

import links from 'src-sites/bildcontrol/links';

import translates from '../home-i18n.json';

const classes = new Bem('blockIndependent');

@I18nHoc(translates)
export default class IndependentBlock extends Component {
  static propTypes = {
    className: PropTypes.string
  };

  render() {
    const { className } = this.props;

    return (
      <div {...classes({ extra: className })}>
        <Trans lang="ru">
          <Row {...classes('row', 'block')} allColumn>
            <Col {...classes('col', 'info')} allPr={0}>
              <div {...classes('text', 'h2')}><h2 children="Bild Control - независимый проект" /></div>
              <Row {...classes('row', 'description')} mpColumn>
                <Col {...classes('col', 'description')} allPr={0}>
                  <p {...classes('text', 'description')} children="Мы не помогаем застройщикам выглядеть лучше, чем они есть. Мы помогаем инвесторам получить наглядную оценку законности строительства и оценить риски при покупке в новостройке." />
                </Col>
                <Col {...classes('col', 'link')} allAuto allPr={0}>
                  <Link
                    {...classes('link')}
                    builder={links.sectionHowWeCalculateScore}
                  >
                    <span {...classes('linkContent')}>
                      <span {...classes('after')} />
                      <span {...classes('text', 'link')} children="Подробнее об оценке" />
                    </span>
                  </Link>
                </Col>
              </Row>
            </Col>
          </Row>
        </Trans>
        <Trans lang="ua">
          <Row {...classes('row', 'block')} allColumn>
            <Col {...classes('col', 'info')} allPr={0}>
              <div {...classes('text', 'h2')}><h2 children="Bild Control - незалежний проєкт" /></div>
              <Row {...classes('row', 'description')} mpColumn>
                <Col {...classes('col', 'description')} allPr={0}>
                  <p {...classes('text', 'description')} children="Ми не допомагаємо забудовникам виглядати краще, ніж вони є. Ми допомагаємо інвесторам отримати наочну оцінку законності будівництва та оцінити ризики при покупці в новобудові." />
                </Col>
                <Col {...classes('col', 'link')} allAuto allPr={0}>
                  <Link
                    {...classes('link')}
                    builder={links.sectionHowWeCalculateScore}
                  >
                    <span {...classes('linkContent')}>
                      <span {...classes('after')} />
                      <span {...classes('text', 'link')} children="Детальніше про оцінку" />
                    </span>
                  </Link>
                </Col>
              </Row>
            </Col>
          </Row>
        </Trans>
      </div>
    );
  }
}
