import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Bem from 'bemHelper';
import { I18nHoc } from 'helpers/i18n';
import Row, { Col } from 'components/row';
import SearchIndex from 'components/search-index';
import Icon, { iconTypes } from 'components/icon';

import links from 'src-sites/bildcontrol/links';

import translates from '../home-i18n.json';

const classes = new Bem('blockSearch');
const bemClassesSearchInput = new Bem('searchInput');

@I18nHoc(translates)
export default class SearchBlock extends Component {
  static propTypes = {
    className: PropTypes.string
  };

  constructor(props, ctx) {
    super(props, ctx);

    this.renderSearch = this.renderSearch.bind(this);
    this.searchItemSelected = this.searchItemSelected.bind(this);

    this.router = ctx.router;
  }

  searchItemSelected(it) {
    const link = links.sectionLanding(it).href;
    if (link) this.router.push(links.sectionLanding(it).href);
  }

  renderSearch() {
    return (
      <SearchIndex
        {...classes('search')}
        iconPlaceholder=""
        placeholder={this.i18n('homeSearchPlaceholder')}
        queryParams={{
          landing: true,
          entities: ['newbuild'],
          order: [['relevancy', 'desc']]
        }}
        linkBuilder={links.sectionLanding}
        onItemSelected={this.searchItemSelected}
        searchBoxContent={<Icon {...bemClassesSearchInput('icon', 'search')} type={iconTypes.searchBild} />}
        noError
      />
    );
  }

  render() {
    const { className } = this.props;

    return (
      <div {...classes({ extra: className })}>
        <Row {...classes('row', 'block')}>
          <Col {...classes('col', 'info')} allPr={0}>
            <div {...classes('text', 'h2')}><h2 children={this.i18n('homeSearchH2')} /></div>
            <div {...classes('searchContainer')} children={this.renderSearch()} />
          </Col>
        </Row>
      </div>
    );
  }
}
