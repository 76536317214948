import React, { Component } from 'react';
import PropTypes from 'prop-types';
import map from 'lodash/map';
import get from 'lodash/get';
import Bem from 'bemHelper';
import { I18nHoc } from 'helpers/i18n';
import DataProvider from 'api/data-provider';
import Link from 'components/link';
import { Thumb } from 'components/image';
import Row, { Col } from 'components/row';
import Icon, { iconTypes } from 'components/icon';

import translates from '../home-i18n.json';

const classes = new Bem('blockRating');

@I18nHoc(translates)
export default class RatingBlock extends Component {
  static propTypes = {
    className: PropTypes.string,
    mainMenu: PropTypes.shape({}),
    topProjects: PropTypes.shape({}),
  };

  countLivingComplex=({ data }) => {
    if (!data) return null;

    return (
      <Row {...classes('row', 'list')} mpColumn>
        {map(data, (item, index) => this.ratingCard(item, index))}
      </Row>
    );
  };

  ratingCard(item = {}, index) {
    const { i18n } = this;
    const imgId = get(item, 'title_image.id');
    const ratingFilled = get(item, 'points.investorConfidence.filled');
    const ratingAll = get(item, 'points.investorConfidence.all');
    const level = get(item, 'points.level', 'NA');
    const { display_name, url } = item;

    return (
      <Col key={index} {...classes('col', { item: true, [index]: true })} allPr={0}>
        <div {...classes('position')}><span {...classes('text', 'position')} children={`${index + 1} ${i18n('place')}`} /></div>
        <div {...classes('viewTile', 'bc', 'viewTile')}>
          <Link
            {...classes('link', 'fill')}
            href={url}
          >
            <span />
          </Link>
          <div {...classes('imgContainer')}>
            <div {...classes('img')}>
              <Thumb
                {...classes('imgPic')}
                image={{ id: imgId }}
                tagSpan
              />
            </div>
          </div>
          <div {...classes('dataContainer')}>
            <div {...classes('data')}>
              <div {...classes('dataMain')}>
                <div {...classes('location')}>
                  <span {...classes('text')} children={display_name} />
                </div>
                <div {...classes('points')}>
                  <Icon {...classes('icon', { points: true, [level]: level })} type={iconTypes.bc} />
                  <div {...classes('pointsText')}>
                    <span {...classes('text', 'points')} children={ratingFilled} />
                    <span {...classes('text', 'pointsAll')} children={`${i18n('from')} ${ratingAll}`} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Col>
    );
  }

  render() {
    const { i18n } = this;
    const { className, mainMenu, topProjects } = this.props;
    const type = ' ЖК';
    const url = get(mainMenu, 'flatsNewbuild.url');

    return (
      <div {...classes({ extra: className })}>
        <Row {...classes('row', 'block')} mtColumn mpColumn>
          <Col {...classes('col', 'info')} allPr={0}>
            <div {...classes('text', 'h2')}><h2 children={i18n('homeRatingH2', { placeholder: type })} /></div>
            <DataProvider
              preloaded={topProjects}
              preloadedStatic
              renderer={this.countLivingComplex}
            />
            <div {...classes('linkContainer')}>
              <Link
                {...classes('link', 'rating')}
                href={url}
              >
                <span {...classes('linkContent')}>
                  <span {...classes('after')} />
                  <span {...classes('text', 'link')} children={i18n('homeRatingLink', { placeholder: type })} />
                </span>
              </Link>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}
