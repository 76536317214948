import React, { Component } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import Bem from 'bemHelper';
import { I18nHoc } from 'helpers/i18n';
import DataProvider from 'api/data-provider';
import { InputLocality } from 'containers/form';
import Link from 'components/link';
import Row, { Col } from 'components/row';
import Icon, { iconTypes } from 'components/icon';

import linksDomik from 'src-sites/domik/links';

import logoBildControl from 'src-sites/bildcontrol/assets/bc.svg';
import imgReliability from '../assets/countBlockGreen.png';

import translates from '../home-i18n.json';

const classes = new Bem('blockCount');

@I18nHoc(translates)
export default class CountBlock extends Component {
  static propTypes = {
    className: PropTypes.string,
    topic: PropTypes.string,
    topProjects: PropTypes.shape({}),
    reliabilityCheckedCount: PropTypes.shape({}),
    reliabilityInProgressCount: PropTypes.shape({}),
    mainMenu: PropTypes.shape({})
  };

  static countLivingComplex(data) {
    return <div><span {...classes('text', 'count')} children={data.dataCount} /></div>;
  }

  constructor(props) {
    super(props);
    this.state = {
      locality: null,
      group: 'flats'
    };

    this.renderFormContent = this.renderFormContent.bind(this);
  }

  handleChangeLocality = (id) => {
    this.setState({ locality: id });
  };

  renderFormContent() {
    const { i18n } = this;
    const { group, locality } = this.state;
    const url = get(this, 'props.mainMenu.flatsNewbuild.url');

    return (
      <div {...classes('form', 'pageMain')}>
        <InputLocality
          {...classes('item', 'locality')}
          project="bildcontrol"
          countColumnType="newbuilds"
          estateType={group}
          value={locality}
          onChange={this.handleChangeLocality}
          designMode="modal"
          modalBtnProps={{
            iconType: '',
            iconOnly: false,
            iconSecondType: iconTypes.carretBild
          }}
          confirmLocality
          noError
        />
        <div {...classes('item', 'link')}>
          <Link
            {...classes('link')}
            title={i18n('linkShowRating')}
            href={url}
          >
            <span {...classes('linkContent')}>
              <span {...classes('text', 'link')} children={i18n('linkShowRating')} />
              <Icon {...classes('icon', 'link')} type={iconTypes.searchBild} />
            </span>
          </Link>
        </div>
      </div>
    );
  }

  render() {
    const { i18n } = this;
    const {
      className,
      topProjects,
      reliabilityCheckedCount,
      reliabilityInProgressCount,
      topic
    } = this.props;
    const topRating = get(topProjects, 'data[0].points.investorConfidence', {});

    return (
      <div {...classes({ extra: className })}>
        <Row {...classes('row', 'block')} mtColumn mpColumn>
          <Col {...classes('col', 'info')} allPr={0}>
            <div {...classes('text', 'h1')}><h1 children={topic} /></div>
            <Row {...classes('row', 'statistics')} alignIcenter>
              <Col {...classes('col', 'icon')} allAuto allPr={0}>
                <img {...classes('icon', 'bc')} src={logoBildControl} alt={i18n('logoAlt')} loading="lazy" decoding="async" />
              </Col>
              <Col {...classes('col', 'verified')} allAuto allPr={0}>
                <div><span {...classes('text', 'topic')} children={i18n('checkedLC')} /></div>
                <DataProvider
                  preloaded={reliabilityCheckedCount}
                  preloadedStatic
                  renderer={CountBlock.countLivingComplex}
                />
                <div {...classes('separator')} />
              </Col>
              <Col {...classes('col', 'verification')} allAuto allPr={0}>
                <div><span {...classes('text', 'topic')} children={i18n('onCheckedLC')} /></div>
                <DataProvider
                  preloaded={reliabilityInProgressCount}
                  preloadedStatic
                  renderer={CountBlock.countLivingComplex}
                />
              </Col>
            </Row>
            <div {...classes('blockSearch')} children={this.renderFormContent()} />
            <div {...classes('blockCreators')}>
              <span {...classes('text', 'description')}>{i18n('fromMakers')} {renderDomikLink.call(null, classes)}</span>
            </div>
          </Col>
          <Col {...classes('col', 'img')} allPr={0}>
            <div {...classes('blockPoints')}>
              <div {...classes('imgContainer')}>
                <img {...classes('img')} src={imgReliability} alt={i18n('logoGreenAlt')} loading="lazy" decoding="async" />
              </div>
              <Row {...classes('row', 'points')}>
                <Col {...classes('col', 'text')} allPr={0}>
                  <span {...classes('text')} children={i18n('logoGreenAlt')} />
                </Col>
                <Col {...classes('col', 'points')} allAuto allPr={0}>
                  <div {...classes('pointsText')}>
                    <span {...classes('text', 'pointsAll')} children={`${i18n('from')} ${topRating.all}`} />
                    <span {...classes('text', 'points')} children={topRating.filled} />
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

export function renderDomikLink(bemClasses = {}) {
  return (
    <Link
      {...bemClasses('link')}
      builder={linksDomik.sectionDomik}
      builderProps={{ title: 'Domik.ua' }}
      rel="nofollow"
      blank
    >
      <span {...bemClasses('linkContent')}>
        <span {...bemClasses('text', 'link')} children="Domik.ua" />
      </span>
    </Link>
  );
}
