import React, { Component } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import Bem from 'bemHelper';
import { I18nHoc } from 'helpers/i18n';
import cacheTerms from 'helpers/cacheTerms';
import preload from 'api/data-preload';
import seoPreload, { seoDecorator } from 'api/seo';
import { pageCacheStatic } from 'api/page-cache';
import DataProvider from 'api/data-provider';
import GeoLandings from 'subsys/geo-landings';
import { marketStatEvents, mstatDecorator } from 'subsys/market-stat';
import { SeoText } from 'components/layouts';
import Container from 'components/container';

import CountBlock from './blocks/block-count';
import RiskBlock from './blocks/block-risk';
import IndependentBlock from './blocks/block-independent';
import EvaluationBlock from './blocks/block-evaluation';
import RatingBlock from './blocks/block-rating';
import SearchBlock from './blocks/block-search';

import translates from './home-i18n.json';

import 'styles/bildcontrol/section-home/_page-home.scss';

const classes = new Bem('pageMain');

@preload({
  topProjects: [DataProvider.preload, {
    url: 'bild/newbuilds-list',
    queryJson: true,
    query: { order: ['pointsLevel', 'desc'], limit: 3, onlyReliability: true, notReadyStatus: true },
    cache: cacheTerms.longInfoProvider
  }],
  reliabilityCheckedCount: [DataProvider.preload, {
    url: 'bild/newbuilds-list',
    query: { countOnly: true, onlyReliability: true },
    queryJson: true,
    cache: cacheTerms.longInfoProvider
  }],
  reliabilityInProgressCount: [DataProvider.preload, {
    url: 'bild/newbuilds-list',
    query: { countOnly: true, unpublished: true },
    queryJson: true,
    cache: cacheTerms.longInfoProvider
  }],
  ...seoPreload({ entity: 'pageHome' }, []),
  ...pageCacheStatic(cacheTerms.longPage, true)
})
@seoDecorator({
  advancedVars: {
    bottomText: 'Текст в самом низу'
  }
})
@mstatDecorator(marketStatEvents.historyChange, () => ({ entity: 'Главная' }))
@I18nHoc(translates)
export default class BildControlHome extends Component {
  static propTypes = {
    className: PropTypes.string,
    mainMenuBase: PropTypes.shape({}),
    topProjects: PropTypes.shape({}),
    reliabilityCheckedCount: PropTypes.shape({}),
    reliabilityInProgressCount: PropTypes.shape({}),
    seoData: PropTypes.shape({}),
  };

  render() {
    const {
      className, mainMenuBase, topProjects,
      reliabilityCheckedCount, reliabilityInProgressCount, seoData
    } = this.props;
    const seoText = get(seoData, 'bottomText');
    const header = get(seoData, 'header') ? seoData.header : this.i18n('homeH1');

    return (
      <div {...classes({ extra: className })}>
        <GeoLandings
          injectPropName="mainMenu"
          base={mainMenuBase}
        >
          <CountBlock
            {...classes('blockCount')}
            topProjects={topProjects}
            reliabilityCheckedCount={reliabilityCheckedCount}
            reliabilityInProgressCount={reliabilityInProgressCount}
            topic={header}
          />
        </GeoLandings>
        <RiskBlock {...classes('blockRisk')} />
        <IndependentBlock {...classes('blockIndependent')} />
        <EvaluationBlock {...classes('blockEvaluation')} />
        <GeoLandings
          injectPropName="mainMenu"
          base={mainMenuBase}
        >
          <RatingBlock
            {...classes('blockRating')}
            topProjects={topProjects}
          />
        </GeoLandings>
        <SearchBlock {...classes('blockSearch')} />
        <Container body>
          <SeoText data={seoText} />
        </Container>
      </div>
    );
  }
}
