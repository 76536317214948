import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Bem from 'bemHelper';
import { I18nHoc, Trans } from 'helpers/i18n';
import Link from 'components/link';
import Row, { Col } from 'components/row';

import imgRisk from '../assets/imgRisklock.jpg';

import translates from '../home-i18n.json';

const classes = new Bem('blockRisk');

@I18nHoc(translates)
export default class RiskBlock extends Component {
  static propTypes = {
    className: PropTypes.string
  };

  render() {
    const { i18n } = this;
    const { className } = this.props;

    return (
      <div {...classes({ extra: className })}>
        <Trans lang="ru">
          <Row {...classes('row', 'block')}>
            <Col {...classes('col', 'img')} mp={0} allPr={0}>
              <div {...classes('imgContainer')}>
                <img {...classes('img')} src={imgRisk} alt="стройка" loading="lazy" decoding="async" />
              </div>
            </Col>
            <Col {...classes('col', 'info')} allPr={0}>
              <div {...classes('text', 'h2')}><h2 children="Риски покупки недвижимости Украины" /></div>
              <div {...classes('description')}>
                <p {...classes('text', 'description')} children="К сожалению, реалии рынка таковы, что у покупателя новостройки много рисков. Нам знакомы много ситуаций когда инвестор не получает оплаченное жилье или получает его с большими задержками и в низком качестве. Всё это добавляет стресса при покупке квартиры у застройщика." />
              </div>
              <div {...classes('listTopic')}>
                <p {...classes('text', 'ol')} children="Возможные решения:" />
              </div>
              <ol {...classes('list')}>
                <li {...classes('listItem')}>
                  <p {...classes('text', 'topic')} children="Купить «на авось»" />
                  <p {...classes('text', 'description')}>
                    Предварительно ознакомившись с информацией {renderForumLink(classes, 'на форуме Domik.ua')}, блогах и открытых источниках. Самый дешевый вариант, но и самый рискованный.</p>
                </li>
                <li {...classes('listItem')}>
                  <p {...classes('text', 'topic')} children="Помощь юристов" />
                  <p {...classes('text', 'description')} children="Проверять каждый интересующий объект через юристов. Надёжный, но долгий и дорогой способ." />
                </li>
                <li {...classes('listItem')}>
                  <p {...classes('text', 'topic')} children="Мы нашли третье решение" />
                  <p {...classes('text', 'description')} children="Создали сервис, который делает доступным проверку своего ЖК для каждого инвестора. Это экспертная, регулярно обновляемая оценка." />
                </li>
              </ol>
            </Col>
          </Row>
        </Trans>
        <Trans lang="ua">
          <Row {...classes('row', 'block')}>
            <Col {...classes('col', 'img')} mp={0} allPr={0}>
              <div {...classes('imgContainer')}>
                <img {...classes('img')} src={imgRisk} alt="будівництво" loading="lazy" decoding="async" />
              </div>
            </Col>
            <Col {...classes('col', 'info')} allPr={0}>
              <div {...classes('text', 'h2')}><h2 children="Ризики покупки нерухомості України" /></div>
              <div {...classes('description')}>
                <p {...classes('text', 'description')} children="На жаль, реалії ринку такі, що у покупця новобудови багато ризиків. Нам знайомі багато ситуацій коли інвестор не отримує оплачене житло або отримує його з великими затримками і в низькій якості. Все це додає стресу при купівлі квартири у забудовника." />
              </div>
              <div {...classes('listTopic')}>
                <p {...classes('text', 'ol')} children="Можливі рішення:" />
              </div>
              <ol {...classes('list')}>
                <li {...classes('listItem')}>
                  <p {...classes('text', 'topic')} children="Купити «на авось»" />
                  <p {...classes('text', 'description')}>
                    Попередньо ознайомившись з інформацією {renderForumLink(classes, 'на форумі Domik.ua')}, блогах і відкритих джерелах. Найдешевший варіант, але і найбільш ризикований.</p>
                </li>
                <li {...classes('listItem')}>
                  <p {...classes('text', 'topic')} children="Допомога юристів" />
                  <p {...classes('text', 'description')} children="Перевіряти кожен об'єкт, що цікавить через юристів. Надійний, але довгий і дорогий спосіб." />
                </li>
                <li {...classes('listItem')}>
                  <p {...classes('text', 'topic')} children="Ми знайшли третє рішення" />
                  <p {...classes('text', 'description')} children="Створили сервіс, який робить доступним перевірку свого ЖК для кожного інвестора. Це експертна, регулярно оновлювана оцінка." />
                </li>
              </ol>
            </Col>
          </Row>
        </Trans>
      </div>
    );
  }
}

function renderForumLink(bemClasses = {}, text) {
  return (
    <Link
      {...bemClasses('link', 'toForum')}
      href="https://forum.domik.ua"
      rel="nofollow"
      noRedirector
      external
      blank
    >
      <span {...bemClasses('linkContent')}>
        <span {...bemClasses('text', 'link')} children={text} />
      </span>
    </Link>
  );
}
